.App {
  color: #f1e6f1;

  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
}

.Column {
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;
  max-width: 1000px;
}

#content {
  flex: 2;
  width: 100%;
}
