.factory {
  border: 10px solid black;
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
}

.letter-input {
  font-size: min(6vw, 60px);
  min-width: 0;
}

.factory-button {
  font-size: min(3vw, 30px);
  white-space: normal;
  -web-kit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: white;
}
