.letter {
  position: absolute;
  font-family: "Courier New", Courier, monospace;
  color: white;
}

.trashcan {
  position: absolute;
  font-size: 10px;
  background-color: black;
}
