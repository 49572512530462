.HeaderColumn {
  color: #f1e6f1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1 {
  line-height: min(6vw, 60px);
  font-size: min(6vw, 60px);
}

a {
  cursor: pointer;
  line-height: 4vw;
  font-size: min(3vw, 30px);
}

a:hover {
  background-color: yellow;
  color: black;
}
